/** @jsx jsx */
import { jsx, Flex, Box, Styled } from 'theme-ui';
import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { AccountDetailsFormik } from './AccountDetailsForm';
import AccountDetail from './AccountDetail';
import SMSVerificationPopup from './SMSVerificationPopup';
import { updateUser, getLocale } from '../state/session';
import Button from '../components/Button';
import localizationFi from '../localization/fi.json';
import localizationSv from '../localization/sv.json';
import localizationEn from '../localization/en.json';
import getLanguage from '../utils/getLanguage';

const getCountries = language => {
  switch (language) {
    case 'sv':
      return localizationSv.countries;
    case 'en':
      return localizationEn.countries;
    default:
      return localizationFi.countries;
  }
};

const AccountDetails = () => {
  const localize = useSelector(state => state.localize);
  const translate = getTranslate(localize);
  const user = useSelector(state => state.session.user);
  const dispatch = useDispatch();
  const language = useSelector(state => getLanguage(state));
  const countries = getCountries(language);

  const [editMode, setEditMode] = useState(false);
  const [smsPopupVisible, setSmsPopupVisible] = useState(false);

  const onSubmit = useCallback(
    async (user, { setSubmitting }) => {
      setEditMode(false);
      dispatch(
        updateUser({
          ...user,
          phoneNumber: user.phoneNumber.replace(/\s/g, ''),
        })
      );
      setSubmitting(false);
    },
    [dispatch]
  );

  const requestSMSverification = useCallback(() => {
    setSmsPopupVisible(true);
  }, [setSmsPopupVisible]);

  const formProps = { user, onSubmit, translate, countries };
  const hasUnverifiedPhoneNumber = (user.phoneNumber || '').length > 5 && !user.phoneVerified;

  const isApplePrivate = (user.email || '').toLowerCase().endsWith('privaterelay.appleid.com');
  const altEmailIsVerified = user.altEmail?.verified;

  return editMode == true ? (
    <AccountDetailsFormik {...formProps} />
  ) : (
    <>
      <Flex sx={{ mb: 3, justifyContent: 'space-between' }}>
        <Styled.h2 sx={{ m: 0 }}>{translate('account.contactInformation')}</Styled.h2>
        <Button
          variant="small"
          onClick={e => {
            e.preventDefault();
            setEditMode(true);
          }}
          sx={{ flex: 'none' }}
        >
          {translate('account.editButton')}
        </Button>
      </Flex>
      <AccountDetail
        label={isApplePrivate ? translate('account.accountEmail') : translate('account.email')}
        value={user.email}
      />
      {isApplePrivate && altEmailIsVerified && (
        <AccountDetail label={translate('account.email')} value={user.altEmail.email} />
      )}
      {user.company && <AccountDetail label={translate('account.companyName')} value={user.companyName} />}

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr 1fr', 'auto'],
          gridGap: [3, 0],
        }}
      >
        <AccountDetail
          label={user.company ? translate('account.companyFirstname') : translate('account.firstname')}
          value={user.firstname}
        />
        <AccountDetail
          label={user.company ? translate('account.companyLastname') : translate('account.lastname')}
          value={user.lastname}
        />
      </Box>

      <Flex>
        <AccountDetail
          label={user.company ? translate('account.companyPhoneNumber') : translate('account.phoneNumber')}
          value={user.phoneNumber || ''}
        />
        {hasUnverifiedPhoneNumber && (
          <Box sx={{ ml: 3, mb: [2, 1], color: 'primary', alignSelf: 'flex-end' }} onClick={requestSMSverification}>
            {translate('smsVerification.pleaseVerify')}&nbsp;&gt;
          </Box>
        )}
      </Flex>
      {smsPopupVisible && <SMSVerificationPopup setVisible={setSmsPopupVisible} />}

      <AccountDetail label={translate('account.language')} value={translate(`account.form.${getLocale(user)}`)} />
      <AccountDetail label={translate('account.streetAddress')} value={user.streetAddress} />
      <AccountDetail label={translate('account.postNumber')} value={user.postNumber} />

      <AccountDetail label={translate('account.postOffice')} value={user.postOffice} />
      <AccountDetail label={translate('account.country')} value={countries[user.country] || user.country} />

      {user.company && <AccountDetail label={translate('account.vatNumber')} value={user.vatNumber} />}
    </>
  );
};

export default AccountDetails;
