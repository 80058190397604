/** @jsx jsx */
import { jsx, Box, Styled } from 'theme-ui';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { Form } from 'formik';
import Button from '../components/Button';
import FormField from '../components/FormField';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { Select } from '../components/Select';
import { getLocale } from '../state/session';

const phoneNumberRegExp = /^\+?[0-9\s]{6,18}$/;
const postCodeRegExp = /^[0-9]{5}$/;
const vatNumberRegExp = /^[0-9]{7}-[0-9]{1}$/;

const accountDetailsSchema = translate =>
  yup.object().shape({
    companyName: yup.string().when('company', {
      is: true,
      then: yup.string().required(translate('register.message.requiredField')),
    }),
    firstname: yup
      .string()
      .required(translate('register.message.requiredField')),
    lastname: yup
      .string()
      .required(translate('register.message.requiredField')),
    phoneNumber: yup
      .string()
      .required(translate('register.message.requiredField'))
      .matches(
        phoneNumberRegExp,
        translate('register.message.invalidPhoneNumber')
      ),
    language: yup
      .string()
      .oneOf(['fi', 'sv', 'en'], translate('account.message.invalidLanguage')),
    streetAddress: yup.string(),
    postNumber: yup
      .string()
      .matches(postCodeRegExp, translate('account.message.invalidPostNumber')),
    postOffice: yup.string(),
    country: yup.string(),
    vatNumber: yup
      .string()
      .matches(vatNumberRegExp, translate('account.message.invalidVatNumber')),
  });

export const AccountDetailsForm = ({
  values,
  handleSubmit,
  isSubmitting,
  countries,
}) => {
  const translate = getTranslate(useSelector(state => state.localize));
  return (
    <Form sx={{ maxWidth: 640 }}>
      <Styled.h2 sx={{ mt: 0 }}>
        {translate('account.contactInformation')}
      </Styled.h2>
      <FormField
        label={translate('account.form.companyName')}
        hidden={!values.company}
        name="companyName"
      />
      <Box sx={{ display: 'grid', gridGap: 3, gridTemplateColumns: '1fr 1fr' }}>
        <FormField
          label={
            values.company
              ? translate('account.form.companyFirstname')
              : translate('account.form.firstname')
          }
          name="firstname"
        />
        <FormField
          label={
            values.company
              ? translate('account.form.companyLastname')
              : translate('account.form.lastname')
          }
          name="lastname"
        />
      </Box>
      <FormField
        label={
          values.company
            ? translate('account.form.companyPhoneNumber')
            : translate('account.form.phoneNumber')
        }
        name="phoneNumber"
      />
      <FormField
        as={Select}
        name="language"
        label={translate('account.form.language')}
      >
        <option value="fi">{translate('account.form.fi')}</option>
        <option value="sv">{translate('account.form.sv')}</option>
        <option value="en">{translate('account.form.en')}</option>
      </FormField>
      <FormField
        label={translate('account.form.streetAddress')}
        name="streetAddress"
      />
      <Box sx={{ display: 'grid', gridGap: 3, gridTemplateColumns: '1fr 2fr' }}>
        <FormField
          label={translate('account.form.postNumber')}
          name="postNumber"
        />
        <FormField
          label={translate('account.form.postOffice')}
          name="postOffice"
        />
      </Box>
      <FormField
        as={Select}
        name="country"
        label={translate('account.form.country')}
      >
        <option value={''}>{translate('account.form.defaultSelection')}</option>
        {Object.entries(countries).map(([key, value]) => (
          <option key={key} value={key}>
            {value}
          </option>
        ))}
      </FormField>
      <FormField
        label={translate('account.form.vatNumber')}
        hidden={!values.company}
        name="vatNumber"
      />
      <Button
        style={{ alignSelf: 'flex-end' }}
        type="submit"
        onClick={handleSubmit}
        disabled={isSubmitting}
      >
        {translate('account.saveButton')}
      </Button>
    </Form>
  );
};

export const AccountDetailsFormik = withFormik({
  mapPropsToValues: ({ user }) =>
    Object.assign({
      firstname: user.firstname || '',
      lastname: user.lastname || '',
      phoneNumber: user.phoneNumber || '',
      company: user.company || false,
      companyName: user.companyName || '',
      language: getLocale(user) || 'fi',
      streetAddress: user.streetAddress || '',
      postNumber: user.postNumber || '',
      postOffice: user.postOffice || '',
      country: user.country || '',
      vatNumber: user.vatNumber || '',
    }),
  validationSchema: ({ translate }) => {
    return accountDetailsSchema(translate);
  },
  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    onSubmit(values, actions);
  },
  displayName: 'AccountDetailsInputForm',
})(AccountDetailsForm);
