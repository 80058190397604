/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { Form } from 'formik';
import Button from '../components/Button';
import FormField from '../components/FormField';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { Select } from '../components/Select';

const accountServicesSchema = translate =>
  yup.object().shape({
    priceGroup: yup
      .string()
      .oneOf(
        ['0', '2', '3', '4', '5', '6', '8'],
        translate('account.message.invalidPriceGroup')
      ),
    priceCode: yup
      .string()
      .min(5, translate('account.message.invalidPriceCode'))
      .max(10, translate('account.message.invalidPriceCode')),
  });

export const AccountServicesForm = props => {
  const translate = getTranslate(useSelector(state => state.localize));
  return (
    <Form
      sx={{
        maxWidth: 640,
      }}
    >
      <FormField
        as={Select}
        name="priceGroup"
        label={translate('account.form.priceGroup')}
      >
        <option value="0">{translate('account.form.priceGroup_0')}</option>
        <option value="2">{translate('account.form.priceGroup_2')}</option>
        <option value="3">{translate('account.form.priceGroup_3')}</option>
        <option value="4">{translate('account.form.priceGroup_4')}</option>
        <option value="5">{translate('account.form.priceGroup_5')}</option>
        <option value="6">{translate('account.form.priceGroup_6')}</option>
        <option value="8">{translate('account.form.priceGroup_8')}</option>
      </FormField>
      <FormField
        label={translate('account.form.priceCode')}
        name="priceCode"
        helperText={translate('account.form.priceCodeHelp')}
      />
      <Box>
        <Button
          type="submit"
          onClick={props.handleSubmit}
          disabled={props.isSubmitting}
        >
          {translate('account.saveButton')}
        </Button>
      </Box>
    </Form>
  );
};

export const AccountServicesFormik = withFormik({
  mapPropsToValues: ({ user }) =>
    Object.assign({
      priceGroup: user.priceGroup || '0',
      priceCode: user.priceCode || '',
    }),
  validationSchema: ({ translate }) => {
    return accountServicesSchema(translate);
  },
  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    onSubmit(values, actions);
  },
  displayName: 'AccountServicesInputForm',
})(AccountServicesForm);
