/** @jsx jsx */
import { jsx, Flex, Box } from 'theme-ui';
import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import * as api from '../utils/api';
import { useEffect } from 'react';
import { Form, withFormik } from 'formik';
import Spinner from '../components/Spinner';
import * as yup from 'yup';
import Button from '../components/Button';
import FormField from '../components/FormField';
import { showNotification, SEVERITY } from '../state/notifications';
import { setPhoneVefified } from '../state/session';
import { reloadShipments } from '../state/shipments';

const SMSCodeForm = ({ isSubmitting, translate }) => {
  return (
    <Form
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'flex-start',
        ml: 4,
        mt: 2,
        maxWidth: 400,
      }}
    >
      <Box sx={{ width: '100%', pr: 2 }}>
        <FormField name="smsCode" label={translate('smsVerification.enter')} sx={{ pr: 4 }} />
      </Box>
      <Button
        variant="plain"
        type="submit"
        disabled={isSubmitting}
        sx={{
          position: 'absolute',
          top: 20,
          right: 2,
          zIndex: 1,
          display: 'block',
          px: 2,
          fontSize: 24,
        }}
      >
        &rarr;
      </Button>
      {isSubmitting && (
        <Box sx={{ position: 'absolute', top: '31px', right: '-10px' }}>
          <Spinner />
        </Box>
      )}
    </Form>
  );
};

const SMSCodeFormik = withFormik({
  mapPropsToValues: () => ({
    smsCode: '',
  }),
  validationSchema: ({ translate }) =>
    yup.object().shape({
      smsCode: yup.string().min(4, translate('smsVerification.tooShort')),
    }),
  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    return onSubmit(values, actions);
  },
  displayName: 'SMSCodeFormik',
})(SMSCodeForm);

const STATE = {
  INIT: 0,
  REQUESTED: 1,
  SMS_SENT: 2,
  VERIFIED: 3,
  WRONG_CODE: 4,
};

export default ({ setVisible, ...other }) => {
  const localize = useSelector(state => state.localize);
  const translate = getTranslate(localize);
  const dispatch = useDispatch();
  const closePopup = useCallback(() => setVisible(false), [setVisible]);
  const [state, setState] = useState(STATE.INIT);

  useEffect(() => {
    if (state === 0) {
      setState(STATE.REQUESTED);
      (async () => {
        const response = await api.requestPhoneVeficationSMS();
        if (response.alreadyVerified) {
          dispatch(showNotification('smsVerification.verified', SEVERITY.INFO));
          dispatch(setPhoneVefified());
          dispatch(reloadShipments());
          closePopup();
          return;
        }
        setState(STATE.SMS_SENT);
      })();
    }
  }, [state]);

  const onSubmit = useCallback(
    async (values, { setFieldError }) => {
      const code = values.smsCode.trim();
      if (!code) {
        return;
      }
      try {
        await api.verifyPhoneNumber(code);
        // setState(STATE.VERIFIED);
        dispatch(showNotification('smsVerification.verified', SEVERITY.INFO));
        dispatch(setPhoneVefified());
        dispatch(reloadShipments());
        closePopup();
      } catch (err) {
        if (err.response && err.response.status === 403) {
          setState(STATE.WRONG_CODE);
        } else {
          dispatch(showNotification('genericApiError'));
        }
      }
    },
    [dispatch, closePopup]
  );

  let message = translate('smsVerification.codeSent');
  if (state === STATE.WRONG_CODE) {
    message = translate('smsVerification.wrongCode');
  } else if (state === STATE.VERIFIED) {
    message = translate('smsVerification.verified'); // not used
  }

  const formProps = { onSubmit, translate };

  return (
    <div sx={{ position: 'relative' }}>
      <div
        sx={{
          /* position: 'absolute',
          right: 0, */
          float: 'right',
          minWidth: '80%',
          bg: 'white',
          border: '1px solid',
          borderColor: 'primary',
          borderRadius: 2,
          p: 3,
        }}
      >
        <div sx={{ float: 'right' }} onClick={closePopup}>
          X
        </div>
        <h3>{message}</h3>
        {[STATE.SMS_SENT, STATE.WRONG_CODE].includes(state) && <SMSCodeFormik {...formProps} />}
      </div>
      <div sx={{ clear: 'both' }} />
    </div>
  );
};
