/** @jsx jsx */
import { jsx, Styled, Box, Button } from 'theme-ui';
import { useSelector, useDispatch } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import AccountLayout from '../account/AccountLayout';
import AccountDetails from '../account/AccountDetails';
import AccountCards from '../account/AccountCards';
import AccountPermissions from '../account/AccountPermissions';
import AccountServices from '../account/AccountServices';
import ReceiptHero from '../account/ReceiptHero';
import { removeProfile } from '../state/session';
import { Link2 } from '../components/Link';
import { BookingWidget } from '../components/widgets';
import * as analytics from '../utils/analytics';
import QRCodeLoginWidget from '../components/widgets/QRCodeLoginWidget';
import { Link } from '../components';

const isTest = process.env.GATSBY_ACTIVE_ENV !== 'production';

const MyAccountPage = ({ pageContext }) => {
  analytics.usePageCategory('omat_sivut');
  const localize = useSelector(state => state.localize);
  const translate = getTranslate(localize);
  const user = useSelector(state => state.session.user);
  const dispatch = useDispatch();
  const removeAction = async () => {
    var remove = confirm(translate('account.removeProfileConfirmation'));
    if (remove) {
      dispatch(removeProfile());
    }
  };

  return !user ? null : (
    <AccountLayout
      title={translate('account.header')}
      paths={pageContext.paths}
      locale={pageContext.locale || 'en'}
      darkMode={true}
      footer={false}
      showNavigation={false}
      sidebar={
        <Box>
          <BookingWidget sidebar />
          <QRCodeLoginWidget sx={{ mt: 4 }} sidebar />
        </Box>
      }
    >
      <Box sx={{ py: 4 }}>
        <AccountDetails />
        <Styled.hr />
        <AccountCards />
        <Styled.hr />
        <AccountServices />
        <Styled.hr />
        <AccountPermissions />
        <Styled.hr />
        <ReceiptHero />
        <Styled.hr />

        <Box sx={{ mt: 0, mb: 2 }}>
          <Button as={Link2} variant="plain" to="/tietosuoja" noTitleFix>
            {translate('account.toPrivacyPolicy')}
          </Button>
        </Box>

        {/*user.userId.startsWith('MH_') && (
            <Button as={Link} variant="plain" to="/change-password">
              {translate('page.changePassword')}
            </Button>
          )*/}
        <Button variant="plain" as={Link} to="/delete-profile">
          {translate('account.deleteProfileTitle')}
        </Button>
      </Box>
    </AccountLayout>
  );
};

export default MyAccountPage;
