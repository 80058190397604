/** @jsx jsx */
import { jsx, Box, Styled } from 'theme-ui';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { updateUser } from '../state/session';
import Switch from '../components/Switch';

const AccountPermissions = () => {
  const localize = useSelector(state => state.localize);
  const translate = getTranslate(localize);
  const user = useSelector(state => state.session.user);
  const emailChecked = typeof user.emailMarketingPermission === 'boolean' ? user.emailMarketingPermission : true;
  const smsChecked = typeof user.smsMarketingPermission === 'boolean' ? user.smsMarketingPermission : false;
  const [smsPermission, setSMSPermission] = useState(smsChecked);
  const [emailPermission, setEmailPermission] = useState(emailChecked);
  const dispatch = useDispatch();

  const changeEmailPermission = () => {
    const newValue = !emailPermission;
    setEmailPermission(newValue);
    dispatch(updateUser({ emailMarketingPermission: newValue }));
  };

  const changeSmsPermission = () => {
    const newValue = !smsPermission;
    setSMSPermission(newValue);
    dispatch(updateUser({ smsMarketingPermission: newValue }));
  };

  return (
    <>
      <Styled.h2 sx={{ mt: 0, mb: 3 }}>{translate('account.notifications')}</Styled.h2>
      <Styled.p>{translate('account.marketingPermissions')}</Styled.p>
      <Box sx={{ mb: 2 }}>
        <Switch defaultChecked={emailChecked} onChange={changeEmailPermission}>
          {translate('account.emailMarketingPermission')}
        </Switch>
      </Box>
      <Switch defaultChecked={smsChecked} onChange={changeSmsPermission}>
        {translate('account.smsMarketingPermission')}
      </Switch>
    </>
  );
};

export default AccountPermissions;
