/** @jsx jsx */
import { jsx, Flex, Box } from 'theme-ui';

const AccountDetail = ({ label, value }) => (
  <Flex
    sx={{
      mb: [2, 1],
      flexDirection: ['column', 'row'],
      flexWrap: ['wrap', 'nowrap'],
    }}
  >
    <Box sx={{ width: ['auto', 200], fontSize: [1, 2], fontWeight: 'medium', color: 'black' }}>{label}</Box>
    <Box sx={{ color: 'black' }}>{value}</Box>
  </Flex>
);

export default AccountDetail;
