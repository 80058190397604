/** @jsx jsx */
import { jsx, Box, Styled } from 'theme-ui';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Spinner, Switch } from '../components';
import { showNotification } from '../state/notifications';
import { useUser, updateReceiptHero } from '../state/session';
import * as api from '../utils/api';
import { useTranslate } from '../utils/getLanguage';

const isTest = process.env.GATSBY_ACTIVE_ENV !== 'production';
const heroServer = isTest ? 'https://dev.receipthero.io' : 'https://receipthero.io';
const authURL = heroServer + '/auth';

export default () => {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const user = useUser();
  const isConnected = !!user.receiptHero;
  const [isBusy, setBusy] = useState(false);

  const doAuth = useCallback(async () => {
    if (isBusy) {
      return false;
    }
    setBusy(true);
    let response;
    try {
      response = await api.startReceiptHeroAuthentication();
    } catch {
      // no-op
    }

    const { state, client_id } = response;
    if (!state) {
      setBusy(false);
      return;
    }
    const redirect_uri = window.location.origin + '/rh-callback';
    const params = new URLSearchParams({
      response_type: 'code',
      client_id,
      redirect_uri,
      scope: 'receipt:write',
      state,
    });
    const url = authURL + '?' + params.toString();
    window.location.assign(url);
  }, [isBusy]);

  const unlinkAccout = useCallback(async () => {
    try {
      await api.revokeReceiptHeroAuthenticaion();
      await dispatch(updateReceiptHero(false));
    } catch (error) {
      dispatch(showNotification('genericApiError'));
    }
  }, [dispatch]);

  const confirmUnlink = useCallback(async () => {
    const unlink = confirm(translate('account.receiptHero.unlink'));
    if (unlink) {
      unlinkAccout();
    }
  }, [unlinkAccout, translate]);

  const changeValue = useCallback(async () => {
    if (!isConnected) {
      return doAuth();
    } else if (isConnected) {
      return confirmUnlink();
    }
  }, [doAuth, confirmUnlink, isConnected]);

  return (
    <>
      <Styled.h2 sx={{ mt: 0, mb: 3 }}>{translate('account.receiptHero.title')}</Styled.h2>
      <Box sx={{ mb: 2, position: 'relative' }}>
        {isBusy && (
          <Box sx={{ position: 'absolute', zIndex: 1, left: '24px', top: '40px' }}>
            <Spinner />
          </Box>
        )}
        <Switch checked={isConnected} onChange={changeValue}>
          {translate(isConnected ? 'account.receiptHero.linked' : 'account.receiptHero.description')}
        </Switch>
        {isConnected && (
          <Box sx={{ my: 3, fontWeight: 'medium' }}>
            <a sx={{ py: 2 }} href={heroServer + '/receipts'} target="_blank" rel="noreferrer">
              {translate('account.receiptHero.view')}
            </a>
          </Box>
        )}
      </Box>
    </>
  );
};
