/** @jsx jsx */
import { jsx, Box, Styled } from 'theme-ui';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { getCards, removeCard } from '../state/session';
import Button from '../components/Button';

// todo: Payex card names, remove?
const cardNames = {
  MC: 'MasterCard',
  VISA: 'Visa',
};

const AccountCard = ({ card, translate, remove }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: ['1fr 1fr 1fr auto', 'repeat(4, 1fr)'],
      }}
    >
      <Box>{card.cardType in cardNames ? cardNames[card.cardType] : card.cardType}</Box>
      <Box sx={{ textAlign: 'right' }}>{card.maskedCardNumber}</Box>
      <Box sx={{ textAlign: 'right' }}>{card.endDate}</Box>
      <Button
        variant="plain"
        onClick={e => {
          e.preventDefault();
          remove(card.id);
        }}
        sx={{ textAlign: 'right', ml: 2, py: 0 }}
      >
        {translate('account.removeButton')}
      </Button>
    </Box>
  );
};
const AccountCards = () => {
  const localize = useSelector(state => state.localize);
  const translate = getTranslate(localize);
  const user = useSelector(state => state.session.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user.cards) {
      dispatch(getCards());
    }
  }, [user, dispatch]);

  const remove = async cardId => {
    var remove = confirm(translate('account.removeCardConfirmation'));
    if (remove) {
      console.log('Remove card: ' + cardId);
      dispatch(removeCard(cardId));
    }
  };

  const cardItems = user.cards
    ? user.cards.map(card => <AccountCard key={card.id} card={card} translate={translate} remove={remove} />)
    : [];

  return (
    <>
      <Styled.h2>{translate('account.cards')}</Styled.h2>
      <Box sx={{ mb: 3 }}>
        <Styled.p>{translate('account.cardsNotification')}</Styled.p>
      </Box>
      {cardItems}
    </>
  );
};

export default AccountCards;
