/** @jsx jsx */
import { jsx, Flex, Box, Styled } from 'theme-ui';
import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { AccountServicesFormik } from './AccountServicesForm';
import AccountDetail from './AccountDetail';
import { updateUser } from '../state/session';
import Button from '../components/Button';
import Switch from '../components/Switch';

const AccountServices = () => {
  const localize = useSelector(state => state.localize);
  const translate = getTranslate(localize);
  const user = useSelector(state => state.session.user);
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);
  const priceGroup = user.priceGroup || 0;

  const onSubmit = useCallback(
    async (user, { setSubmitting }) => {
      setEditMode(false);
      dispatch(updateUser(user));
      setSubmitting(false);
    },
    [dispatch]
  );

  const formProps = { user, onSubmit, translate };

  return editMode == true ? (
    <AccountServicesFormik {...formProps} />
  ) : (
    <>
      <Flex sx={{ justifyContent: 'space-between', mb: 3 }}>
        <Styled.h2 sx={{ m: 0 }}>
          {translate('account.navigation.myTrips')}
        </Styled.h2>
        <Button
          sx={{ flex: 'none' }}
          variant="small"
          onClick={e => {
            e.preventDefault();
            setEditMode(true);
          }}
        >
          {translate('account.editButton')}
        </Button>
      </Flex>
      <AccountDetail
        label={translate('account.priceGroup')}
        value={translate(`account.form.priceGroup_${priceGroup}`)}
      />

      <AccountDetail
        label={translate('account.priceCode')}
        value={user.priceCode}
      />
    </>
  );
};

export default AccountServices;
